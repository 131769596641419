// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-js": () => import("./../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-homesolar-js": () => import("./../src/pages/homesolar.js" /* webpackChunkName: "component---src-pages-homesolar-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-solar-review-2021-solvana-web-to-lead-form-js": () => import("./../src/pages/SolarReview2021SolvanaWebToLeadForm.js" /* webpackChunkName: "component---src-pages-solar-review-2021-solvana-web-to-lead-form-js" */),
  "component---src-pages-storage-js": () => import("./../src/pages/storage.js" /* webpackChunkName: "component---src-pages-storage-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

