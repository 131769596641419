/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';


import Header from './header';
import './layout.css';
import '../grid.css';

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <Header siteTitle={data.site.siteMetadata.title} />
                <div
                    style={{
                        margin: '0 auto',
                        maxWidth: 1400,
                        padding: '0px 1.0875rem 1.45rem',
                        paddingTop: 0,
                        /* minHeight: '100vh',  will cover the 100% of viewport */
                        overflow: 'hidden',
                        display: 'block',
                        position: 'relative',
                        paddingBottom: '100px', /* height of your footer */
                    }}
                >
                    <main>{children}</main>
                    <footer className="footer row">
                        <div className="col span-1-of-1">
                            © 2021 ACDC Solar, LLC - ALL RIGHTS RESERVED
                        </div>
                    </footer>
                </div>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
