import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Layout from '../components/layout';
import SEO from '../components/seo';
import miscTerms from '../components/Learn/miscTerms';
import equipmentTerms from '../components/Learn/equipmentTerms';
import financialTerms from '../components/Learn/financialTerms';
import ContactBtn from '../components/ButtonStack/contactBtn';

import '../grid.css';

const equipment = equipmentTerms.map(terms => (
    <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{terms.term}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Typography>
                {terms.value}
            </Typography>
        </ExpansionPanelDetails>
    </ExpansionPanel>
));
const financial = financialTerms.map(terms => (
    <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{terms.term}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Typography>
                {terms.value}
            </Typography>
        </ExpansionPanelDetails>
    </ExpansionPanel>
));
const misc = miscTerms.map(terms => (
    <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{terms.term}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Typography>
                {terms.value}
            </Typography>
        </ExpansionPanelDetails>
    </ExpansionPanel>
));

export default () => (
    <Layout>
        <SEO
            title="Learn Solar"
        />
        <h1>Solar 101</h1>
        <div className="row">
            <ContactBtn className="col span-2-of-2" label="Learn how Solar can help you!" />
        </div>
        <div>
            <h3 style={{ marginTop: '30px' }}>Solar Equipment</h3>
            {equipment}
            <h3 style={{ marginTop: '30px' }}>Financial Terms</h3>
            {financial}
            <h3 style={{ marginTop: '30px' }}>Misc Concepts</h3>
            {misc}
        </div>
    </Layout>
);
