import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BusinessBtn from '../components/ButtonStack/businessBtn';
import ContactBtn from '../components/ButtonStack/contactBtn';
import StorageBtn from '../components/ButtonStack/storageBtn';
import HomeBtn from '../components/ButtonStack/homesolarBtn';
import LearnBtn from '../components/ButtonStack/learnBtn';
import Image1 from '../components/images/Index1';
import Image2 from '../components/images/Index2';
import Image3 from '../components/images/Index3';
import Image4 from '../components/images/Index4';
import Image5 from '../components/images/Index5';
import Image6 from '../components/images/Index6';
import Image7 from '../components/images/Index7';
import Image8 from '../components/images/Index8';
import Image9 from '../components/images/Index9';
import Image10 from '../components/images/Index10';
import Image11 from '../components/images/Index11';
import Image12 from '../components/images/Index12';
import Image13 from '../components/images/Index13';
import Storage2 from '../components/images/Storage2';


import '../grid.css';
import '../components/index.css';

const IndexPage = () => (
    <Layout>
        <SEO
            title="New Jersey Solar Installation"
        />
        <ContactBtn label="Contact us to for a hassle-free quote" />
        <div className="row">
            <div className="col span-1-of-2">
                <p className="title">Since 2013, ACDC Solar </p>
                <p className="acdcBlob">
                    has offered turnkey solutions for Solar PV and Energy Storage. Our team of experts focus on engineering custom systems for homes and business. We believe that it does not make sense to rent electricity anymore. ACDC Solar works smarter to lower our installation price by using our proprietary digital proposals, limiting our overhead, and job costing every job. At the end of the day, you only pay for what we install.
                </p>
            </div>
            <div className="col span-1-of-2">
                <br />
                <br />
                <HomeBtn />
                <BusinessBtn />
                <StorageBtn />
                <LearnBtn />
            </div>
        </div>
        <div className="row">
            <div className="col span-1-of-4">
                <Storage2 />
            </div>
            <div className="col span-1-of-4">
                <Image11 />
            </div>
            <div className="col span-1-of-4">
                <Image12 />
            </div>
            <div className="col span-1-of-4">
                <Image13 />
            </div>
        </div>
        <div className="row" />
        <iframe title="Buy Solar in Minutes" src="https://player.vimeo.com/video/649767808?h=540d44aec7" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscree ></iframe>

        <div stye={{ marginTop: '30px' }} className="row">
            <div className="col span-1-of-2"><Image1 /></div>
            <div className="col span-1-of-2"><Image2 /></div>
        </div>
        <div stye={{ marginTop: '30px' }} className="row">
            <div className="col span-1-of-2"><Image4 /></div>
            <div className="col span-1-of-2"><Image3 /></div>
        </div>
        <div stye={{ marginTop: '30px' }} className="row">
            <div className="col span-1-of-2"><Image5 /></div>
            <div className="col span-1-of-2"><Image6 /></div>
        </div>
        {' '}
        <div stye={{ marginTop: '30px' }} className="row">

            <div className="col span-1-of-2"><Image7 /></div>
            <div className="col span-1-of-2"><Image8 /></div>
        </div>
        {' '}
        <div stye={{ marginTop: '30px' }} className="row">
            <div className="col span-1-of-2"><Image9 /></div>
            <div className="col span-1-of-2"><Image10 /></div>
        </div>
        {' '}
        <div className="row">
            <ContactBtn label="Contact us to speak with Our Solar Experts" />
        </div>

    </Layout>
);

export default IndexPage;
