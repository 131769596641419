import React from 'react';
import '../../../grid.css';
import BillAfter from '../../images/BillAfter';

const step = {
    fontSize: '1rem',
    padding: '30px',
    minWidth: '100%',
    marginTop: '10px',
    height: 400,
};

const Step6 = () => (
    <div style={step}>
        <h1>Save!</h1>
        <div className="row">
            <p className="col span-1-of-2">After, the utility swaps your meter, we turn your system on, and you start saving money immediately! Fun Fact: Remember the bill from Step 1? This is the same customer&#39;s bill after we installed solar! The remaining $2.83 charge is assessed just for being connected to the electric grid and being a customer. Notice the last two lines on the bill, this customer banked over 2,000kWh, or about $300, just in the the last two months! Now the utility owes him money! How cool is that?  </p>
            <p className="col span-1-of-2"><BillAfter /></p>
        </div>
    </div>
);

export default Step6;
