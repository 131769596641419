import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import StorageTabs from '../components/Storage/StorageTabs';
import '../grid.css';
import ContactBtn from '../components/ButtonStack/contactBtn';
import Storage1 from '../components/images/Storage1';
import Storage2 from '../components/images/Storage2';
import Storage3 from '../components/images/Storage3';
import Storage4 from '../components/images/Storage4';
import Storage5 from '../components/images/Storage5';


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        marginBottom: '25px',
    },
});


function Storage(props) {
    const { classes } = props;

    return (
        <Layout>
            <SEO title="Energy Storage" />
            <div className="row">
                <ContactBtn className="col span-2-of-2" label="Contact us & Never lose Power again!" />
            </div>
            <div className="row">
                <div className="col span-1-of-3">
                    <Storage1 />
                    <Storage2 />
                </div>
                <div className="col span-2-of-3">
                    <h1>Take Charge of your Energy</h1>
                    <div className="row">
                        <div className="col span-1-of-1">
                            <Paper className={classes.root} elevation={3}>
                                <p>
                                    By using Energy Storage Systems (ESS), homes and businesses can save energy generated from solar panels, or charge from the grid, to use it at times when electricity prices are high or there is a blackout. Furthermore, Energy Storage Systems (ESS) make it possible to be completely independent from the grid. There are many things to consider when sizing an ESS and ACDC Solar are experts at this. Find out how adding a ESS to your home or business can provide peace of mind and savings.
                                </p>
                            </Paper>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col span-1-of-1">
                            <h1>Buy 1, Get 3</h1>
                            <StorageTabs />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <Storage3 />
            </div>
            <div className="row">
                <div className="col span-1-of-1">
                    <h1>Customized & Dynamic for All</h1>
                </div>
            </div>
            <div className="row">

                <div className="col span-1-of-3">
                    <Storage4 />
                </div>
                <div className="col span-1-of-3">
                    <Paper className={classes.root} elevation={3}>
                        <p>
                            Green Button Data is like your energy fingerprint, everyone is different. Using your GBD we tailor your system to you. As your GBD changes, so can the programming. We optimize both the size of the solar array and storage system to minimize system costs while maximizing value. A smart idea to power all the ideas that light up your company.
                        </p>
                    </Paper>
                </div>
                <div className="col span-1-of-3">
                    <Storage5 />
                </div>
            </div>

            <div className="row">
                <ContactBtn className="col span-2-of-2" label="Leanr more about SolarPV & Storage" />
            </div>
        </Layout>
    );
}

Storage.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Storage);
