import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SecondPic from '../components/images/hsSecondPic';
import FirstPic from '../components/images/hsFirstPic';
import ThirdPic from '../components/images/hsThirdPic';
import GoSolarStepper from '../components/HomeSolar/GoSolarStepper.js';
import ContactBtn from '../components/ButtonStack/contactBtn';
import Review from '../components/review';
import Image14 from '../components/images/Index14';

import '../grid.css';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        marginBottom: '25px',
    },
});


function Home(props) {
    const { classes } = props;

    return (
        <Layout>
            <SEO
                title="Home Solar"
                keywords={[
                    'solar',
                    'residential solar',
                    'home solar',
                    'batteries',
                    'home batteries',
                    'solar panels',
                    'solar energy',
                    'homesolar',
                    'backup energy',
                    'generator',
                    'electric bill',
                    'utility savings',
                    'home improvement',
                    'blackout',
                    'solar installation',
                    '',

                ]}
            />
            <h1>You own your home but not your electric</h1>
            <h2>
            ... that doesn&#39;t make &nbsp;
                <em>cents</em>
                !
            </h2>
            <Review />
            <br />
            <div className="row">
                <ContactBtn className="col span-2-of-2" label="Get a Free Digital Proposal now!" />
            </div>
            <div className="row">
                <div className="col span-1-of-2">
                    <Paper className={classes.root} elevation={3}>
                        <h4>Serving homeowners in NJ, MA, FL and NYC</h4>
                        <p> Solar panels give you the independence and savings of owning your electricity.  We provide our customers with options to purchase their solar panels so that they can maximize their benefit. Why not own your home AND your electric? We offer:</p>
                        <ul>
                        <li>0$ Down Solar Loans </li>
                        <li>Free Expert Analysis of electric bill</li>
                        <li>Turnkey Installation services</li>
                        </ul>
                        <p>We offer turnkey service and work with you to custom engineer your perfect system using the best solar panels and inverters on the market. We do not price our systems by watt like most other solar companies, that is inaccurate and often times leads to overcharging. ACDC Solar job-costs every job down to the last bolt to ensure our customers only pay for what they get, and nothing more. We provide the best value to our customers and ensure that our equipment & installations are top notch. We do not install anything we wouldn&#39;t install on our own houses!</p>
                        <p> All of our systems are designed storage ready. That means you won&#39;t have to replace costly inverters when you add batteries in the future!</p>
                        <p>
                            Think about it, saving money every year with solar panels is like getting a raise at work... if you spend less of your salary on your home, you have more money at the end of the day right? ACDC Solar believes that installing solar is a no-brainer, and we simply teach our customers about solar until they see the &nbsp;
                            <em>light</em>. Stop renting electricity, create and own your energy future!
                            </p>
                    </Paper>
                </div>
                <div className="col span-1-of-2 pictures">
                    <FirstPic />
                    <br />
                    <Image14 />
                    <br />
                    <ThirdPic />
                    <br />
                    <SecondPic />
                </div>
            </div>
            <h1 style={{ marginTop: '20px' }}>It is EASY to SAVE!</h1>
            <div className="row">
                <div className="col span-1-of-1 stepper">
                    <GoSolarStepper />
                </div>
            </div>
            <div className="row">
                <ContactBtn className="col span-2-of-2" label="Get Free Quote" />
            </div>
        </Layout>
    );
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Home);
