import React from 'react';
import '../../../grid.css';
import Step1img from '../../images/Step1';

const step = {
    fontSize: '1rem',
    padding: '30px',
    minWidth: '100%',
    marginTop: '10px',
    height: 400,
};

const Step1 = () => (
    <div style={step}>
        <h1>Analysis</h1>
        <div className="row">
            <p className="col span-1-of-2">First, we evaluate your home and electric bills for solar worthiness. Then, we design your system based on our decades of experience! We price every job down to the number of bolts, we do not use price per watt averages or include unnecessary charges/equipment. What we in stall on your home, is what we install on our friends&#39; and families&#39; homes. Our proposals are specifically tailored to your needs.</p>
            <p className="col span-1-of-2"><Step1img /></p>
        </div>
    </div>
);

export default Step1;
