import React from 'react';
import Btn from '../Btn';


const homesolarBtn = {
    background: 'linear-gradient(45deg, rgb(0, 170, 246)40%, rgb(0, 170, 246, .7) 95%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    fontSize: '1rem',
    height: 48,
    padding: '30px',
    boxShadow: '0 3px 5px 2px rgb(116, 167, 247, .3)',
    minWidth: '200px',
    marginTop: '10px',
};


const HomeBtn = () => (
    <Btn
        label="Home Solar"
        to="/homesolar"
        className="nav-links"
        style={homesolarBtn}
    />

);

export default HomeBtn;
