import React from 'react';
import { PropTypes } from 'prop-types';

import Btn from '../Btn';


const contactBtn = {
    background: 'linear-gradient(45deg, #ff1683 30%, #fc992f 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    fontSize: '1rem',
    height: 48,
    padding: '30px',
    boxShadow: '0 3px 5px 2px rgb(116, 167, 247, .3)',
    minWidth: '200px',
    marginTop: '10px',
    marginBottom: '10px',
};


const ContactBtn = ({ label }) => (
    <Btn
        label={label}
        to="/contact"
        className="nav-links"
        style={contactBtn}
    />
);

ContactBtn.propTypes = {
    label: PropTypes.string,
};

ContactBtn.defaultProps = {
    label: 'Contact',
};
export default ContactBtn;
