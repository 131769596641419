import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { SocialIcon } from 'react-social-icons';

import MenuIcon from "../images/menu-black-48.png"
import Btn from "./Btn"
import BusinessBtn from "./ButtonStack/businessBtn"
import ContactBtn from "./ButtonStack/contactBtn"
import StorageBtn from "./ButtonStack/storageBtn"
import HomeBtn from "./ButtonStack/homesolarBtn"
import FaqsBtn from "./ButtonStack/learnBtn"
import ACDCLogo from "../components/images/logo"

import "./menu.css"

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}

class MenuBtn extends React.Component {
  state = {
    top: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes } = this.props

    const sideList = (
      <div className={classes.list}>
        <List>
          <Link
            to="/"
            style={{
              color: "#191919",
              textDecoration: "none",
            }}
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          >
            <ACDCLogo />
          </Link>
          <HomeBtn
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          />
          <BusinessBtn
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          />
          <StorageBtn
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          />
          <FaqsBtn
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          />
          <ContactBtn
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          />
          <SocialIcon url="http://instagram.com/acdcsolar" className="socialIcons" target="_blank" rel="noopener" />
          <SocialIcon url="https://www.linkedin.com/company/acdc-solar-llc" className="socialIcons" target="_blank" rel="noopener" />
          <SocialIcon url="http://facebook.com/acdcsolar" className="socialIcons" target="_blank" rel="noopener" />
          <a href="mailto:info@acdcsolar.com" target="_blank" rel="noopener noreferrer"><SocialIcon network="email" className="socialIcons" /></a>
          <h2 style={{marginTop:'15px', marginLeft:'5px'}}>(888) 205-2462</h2>
          <h3 style={{marginTop:'-20px', marginLeft:'5px'}}>Call now to speak with a Solar Expert</h3>
        </List>

      </div>
    )

    return (
      <div>
        <Button onClick={this.toggleDrawer("top", true)}>
          {" "}
          <img src={MenuIcon} alt="Menu Button" style={{ maxWidth: "40px" }} />
        </Button>
        <Drawer open={this.state.top} onClose={this.toggleDrawer("top", false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          >
            {sideList}{" "}
          </div>
        </Drawer>
      </div>
    )
  }
}

MenuBtn.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MenuBtn)
