import React from 'react';
import '../../../grid.css';
import Step3img from '../../images/Step3';


const step = {
    fontSize: '1rem',
    padding: '30px',
    minWidth: '100%',
    marginTop: '10px',
    height: 400,
};

const Step3 = () => (
    <div style={step}>
        <h1>Go Solar!</h1>
        <div className="row">
            <p className="col span-1-of-2">
Going solar is a no-brainer and ACDC Solar makes it easy by reducing the time our presentation, eliminating in-person sales presentations (to reduce costs and emissions), using electronic proposals, and job costing every job. ACDC Solar not only saves you money, but we save you time going solar.
            </p>
            <p className="col span-1-of-2"><Step3img /></p>
        </div>
    </div>
);

export default Step3;
