const equipmentTerms = [
    { term: 'Distributed Energy Resources (DERs)', value: 'DERs are electricity-producing resources or controllable loads that are directly connected to a local distribution system or connected to a host facility within the local distribution system. They include solar PV, ESS, and HVAC or other equipment that utilities can control to regulate peak demand. ' },
    { term: 'Energy Storage System (ESS)', value: 'Also known as a battery, ESS can provide substantial savings on your electric bill, provide backup energy, and add value to your property. When charged only with Solar PV, energy Storage systems are eligible for the ITC and Depreciation. ESS will play a huge part in the future of DERs.' },
    { term: 'Solar Panels 101', value: 'Solar PV panels convert sunlight into "Direct Current". Most residential panels have 60 cells and are about 40”x66”; while commercial panels have 72 cells and are 10” longer. Typical 60-cell panels produce about 325W while commercial panels produce about 375W. Panels come in two types: "Poly" or "Mono".' },
    { term: 'Polysilicon vs Mono-silicon Solar Panels', value: 'The amount of waste silicon is less compared to monocrystalline. Polycrystalline solar panels tend to have slightly lower efficiency. Both perform well, but Mono panels are usually preferred when price and available square footage are not an issue' },
    { term: 'What is string inverter?', value: 'A standard inverter (also known as a string-inverter or central inverter) is a standalone box that is typically installed close to your fuse box and electricity meter. A string inverter functions in a series circuit with there usually being 6-10 solar panels in what is known as a "string". Once combined into strings, the inverter converts the combined DC energy to AC energy, the kind of energy supplied by the electric grid. Technically, inverters search for a sine wave that grid’s energy creates and outputs the AC energy with the same wave; this ensures that when you switch between solar and grid supplied energy your lights do not flicker. Some central inverters come with panel level optimizers which help the conversion efficiency and often come with built in safety feature like “module level rapid shutdown”. Panel level optimizers have similar benefits to Micro Inverters but are preferred by ACDC for numerous reasons. ' },
    { term: 'What is a Micro-inverter?', value: 'They also convert DC to AC but at the panel level, then send AC energy from the roof. ACDC does not typically using Micro Inverters for a few reasons, most notably, they are a pain to maintain and are not cost effective. Imagine having 300 micro inverters on a roof! Sometimes, it makes sense to use microinverters in situations where there are multiple small arrays, like you would find on a residential roof. Or if there is shading that passes over the array throughout the day. The issue with Micro Inverters is that they must be installed one at a time, so there is no scalability on larger systems and often times are not used on systems larger than 150kW due to their cost. Even still, using panel level optimizers are ACDC’s preferred method when dealing with shading, safety, and optimizing efficiency.' },
    { term: 'Rooftop Solar', value: 'Roof mounted solar panels either on a pitched roof common for residential projects or a flat roof more common for commercial applications. ' },
    { term: 'Ground Mounted Solar', value: 'A solar array that is mounted to a structure build specifically and only for solar. This structure typically qualifies for the ITC. Consult your tax advisor for eligibility.' },
    { term: 'Solar Carports', value: 'A structure designed provide a structure for solar panels and covered parking. This structure qualifies for the ITC typically, however, LED lighting does not. Consult your tax advisor for eligibility. ' },
];

export default equipmentTerms;
