import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
    <Layout>
        <SEO
            title="Success"
        />
        <div>

            <meta httpEquiv="Content-type" content="text/html; charset=UTF-8" />

            <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                <input type="hidden" name="oid" defaultValue="00D5e0000011WXT" />
                <input type="hidden" name="retURL" defaultValue="http://www.solvana.io" />

                <label htmlFor="first_name">First Name</label>
                <input id="first_name" maxLength={40} name="first_name" size={20} type="text" />
                <br />
                <label htmlFor="last_name">Last Name</label>
                <input id="last_name" maxLength={80} name="last_name" size={20} type="text" />
                <br />
                <label htmlFor="email">Email</label>
                <input id="email" maxLength={80} name="email" size={20} type="text" />
                <br />
                <label htmlFor="company">Company</label>
                <input id="company" maxLength={40} name="company" size={20} type="text" />
                <br />
                <label htmlFor="street">Street</label>
                <textarea name="street" defaultValue="" />
                <br />
                <label htmlFor="city">City</label>
                <input id="city" maxLength={40} name="city" size={20} type="text" />
                <br />
                <label htmlFor="zip">Zip</label>
                <input id="zip" maxLength={20} name="zip" size={20} type="text" />
                <br />
                <label htmlFor="state">State/Province</label>
                <input id="state" maxLength={20} name="state" size={20} type="text" />
                <br />
                <label htmlFor="lead_source">Lead Source</label>
                <select id="lead_source" name="lead_source">
                    <option value="Solar_Reviews">Solar Reviews</option>
                </select>
                <br />
                Utility:
                <input id="00N5e000006XVkT" maxLength={75} name="00N5e000006XVkT" size={20} type="text" />
                <br />
                Electrical Monthly Spend:
                <input id="00N5e000006XVkd" name="00N5e000006XVkd" size={20} type="text" />
                <br />
                Lead Cost:
                <input id="00N5e000006XVkn" name="00N5e000006XVkn" size={20} type="text" />
                <br />
                Address:
                <input id="00N5e000006XWNT" maxLength={60} name="00N5e000006XWNT" size={20} type="text" />
                <br />
                SR Lead Status:
                <input id="00N5e000006XWNx" maxLength={30} name="00N5e000006XWNx" size={20} type="text" />
                <br />
                System:
                <input id="00N5e000006a97i" maxLength={60} name="00N5e000006a97i" size={20} type="text" />
                <br />
                Date Submitted:
                <span className="dateInput dateOnlyInput"><input id="00N5e000006XWNd" name="00N5e000006XWNd" size={12} type="text" /></span>
                <br />
                <label htmlFor="phone">Phone</label>
                <input id="phone" maxLength={40} name="phone" size={20} type="text" />
                <br />
                <label htmlFor="description">Description</label>
                <textarea name="description" defaultValue="" />
                <br />
                QuoteID:
                <input id="00N5e000006XWN4" maxLength={30} name="00N5e000006XWN4" size={20} type="text" />
                <br />
                Campaign Name:
                <input id="00N5e000006XWNO" maxLength={30} name="00N5e000006XWNO" size={20} type="text" />
                <br />
                <input type="submit" name="submit" />
            </form>

        </div>
    </Layout>
);
