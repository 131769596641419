import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BusinessBtn from '../components/ButtonStack/businessBtn';
import StorageBtn from '../components/ButtonStack/storageBtn';
import HomeBtn from '../components/ButtonStack/homesolarBtn';
import LearnBtn from '../components/ButtonStack/learnBtn';

export default () => (
    <Layout>
        <SEO
            title="Success"
        />
        <div>
            <h2>Success!</h2>
            <h4> We will be in contact with you shortly.</h4>
            <HomeBtn />
            <BusinessBtn />
            <StorageBtn />
            <LearnBtn />
        </div>
    </Layout>
);
