import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
    textTransform: 'capitalize',
  },
});

class DemandShiftPanels extends React.Component {
  state = {
    expanded: null,
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Control when you use your power</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
                We have been taught  that solar power is only available when the Sun is out. Not anymore, with ACDC Solar&#39;s custom designed system, you get to choose when it&#39;s best for you to use your power to target peak demand charges, or you can let us do all the work...
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Become eligible for different rate tariffs</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              When you reduce your peak demand, you may become eligible to change your rate tariff, potentially saving even more!
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

DemandShiftPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DemandShiftPanels);
