import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import UseAnimations from 'react-useanimations';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BusinessPanels from '../components/Business';
import FirstPic from '../components/images/csFirstPic';
import CustomRTCDesign from '../components/images/CustomRTCDesign';
import BrklnCanopy from '../components/images/brklnCanopy';
import ContactBtn from '../components/ButtonStack/contactBtn';
import StorageBtn from '../components/ButtonStack/storageBtn';


import '../grid.css';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        marginBottom: '25px',
    },
});


function Busisnes(props) {
    const { classes } = props;

    return (
        <Layout>
            <SEO title="Commercial Solar" />
            <div className="row">
                <ContactBtn className="col span-2-of-2" label="Learn how your business can save" />
            </div>
            <h1 className="row busTitle">Commercial PV</h1>
            <div className="row">
                <div className="col span-1-of-2 pictures">
                    <FirstPic />
                </div>
                <div className="col span-1-of-2">
                    <Paper className={classes.root} elevation={3}>
                        <p>
                            Putting solar panels on your business is a no-brainer. System owners are eligible for a 26% Investment Tax Credit (ITC) and 100% Depreciation in year one. Then, your system will generate electrical savings for the next 25 years providing significant savings. Plus there are various state incentive programs that your installation may be eligible for. Going solar have never been easier. We offer four ways you can go solar:
                        </p>
                        <ul>
                            <li>Business loans</li>
                            <li>Capital Lease</li>
                            <li>Operating Lease</li>
                            <li>Power Purchase Agreements (PPA)</li>
                        </ul>
                        <StorageBtn label="Learn how Batteries can help your business" />
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col span-1-of-3">
                    <UseAnimations animationKey="infinity" size={56} style={{ padding: 3 }} />
                    <h2 className="row busTitle">Quality Equipment</h2>
                    <p>ACDC Solar prides it&#39;s self in the knowledge we bring to the table. That includes providing you with a range of panel, installation type, and financing options to meet the unique needs of your organization.</p>
                </div>
                <div className="col span-1-of-3">
                    <UseAnimations animationKey="settings" size={56} style={{ padding: 3 }} />
                    <h2 className="row busTitle">Project Managment</h2>
                    <p>Good project management starts with a good design. Our team of engineers meticulously review your system design down to the nuts and bolts we will use well before we step foot on site. This ensures that when we show up to install, there are no surprises.</p>
                </div>
                <div className="col span-1-of-3">
                    <UseAnimations animationKey="heart" size={56} style={{ padding: 3 }} />
                    <h2 className="row busTitle">Real Customer Service</h2>
                    <p>We are a small but powerful team of experts who put our hearts and souls into everything we do. We will provide you with unmatched customer service with the goal of providing you the best value and user experience.</p>
                </div>
            </div>
            <div className="row">
                <h1 className="row busTitle">Custom Design with Turnkey Service</h1>
                <div className="row">
                    <div className="col span-1-of-3">
                        <p className="rtcp">We specialize in custom design, engineering and installation of solar and energy storage systems. Our expert team can create any solution, even a custom rooftop canopy. ACDC Solar is a leader in rooftop and ground mounted solar design and installation. Our systems use the latest equipment and are built to last. Talk to us today to find out how ACDC Solar can make you system perfect for you. </p>
                    </div>
                    <div className="col span-2-of-3"><CustomRTCDesign /></div>
                </div>
            </div>
            <div className="row">
                <div className="col span-2-of-3"><BrklnCanopy /></div>
                <div className="col span-1-of-3">
                    <p className="rtcp2">ACDC is in constant contact with distributors and manufacturers to stay up to date with the latest technology. We regularly attend training with manufacturers so you can have confidence that the correct equipment is used and that your system is designed to last. ACDC Solar custom designed and built this Rooftop Canopy in Brooklyn for a local business using the latest bi-facial solar panels. Bi-facial solar panels can produce up to 30% more energy through an optimized design. This extra production means that we could reduce the number of trusses needed, which cut about 5% of the cost.</p>
                </div>
            </div>

        </Layout>
    );
}
Busisnes.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Busisnes);
