import React from 'react';
import '../../../grid.css';
import Step5img from '../../images/Step5';

const step = {
    fontSize: '1rem',
    padding: '30px',
    minWidth: '100%',
    marginTop: '10px',
    height: 400,
};

const Step5 = () => (
    <div style={step}>
        <h1>Install</h1>
        <div className="row">
            <p className="col span-1-of-2">Once we have your custom engineered solar design,  interconnection approval and permits, we schedule a time that is convenient for you and your family to install. In the past, we&#39;ve work with our customers to work around parties or vacations and will do the same for you. A typical installation takes a few days since inspections by local authorities must take place in between some steps. </p>
            <p className="col span-1-of-2"><Step5img /></p>
        </div>
    </div>
);

export default Step5;
