import React from 'react';
import Btn from '../Btn';


const submitBtn = {
    background: 'linear-gradient(45deg, #ff1683 30%, #fc992f 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    fontSize: '1rem',
    height: 48,
    padding: '30px',
    boxShadow: '0 3px 5px 2px rgb(116, 167, 247, .3)',
    minWidth: '200px',
    marginTop: '10px',
};


const SubmitBtn = () => (
    <Btn
        label="Submit"
        className="nav-links"
        style={submitBtn}
    />

);

export default SubmitBtn;
