import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SubmitBtn from '../components/ButtonStack/submit';

// const months = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December',
// ];
//
// const bills = months.map(month => (
//     <div>
//         <div>{month}</div>
//         <input name={month} placeholder={month} type="file" />
//     </div>
// ));

const Storage = () => (
    <Layout>
        <SEO title="Contact Us" />
        <h1>Contact ACDC Solar</h1>
        <p>info@acdcsolar.com</p>
        <p>(888) 205-2462</p>
        <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/success"
        >
            <input required name="name" placeholder="Name" type="text" />
            <input required name="email" placeholder="Email" type="email" />
            <input required name="phone" placeholder="Phone" type="tel" />
            <textarea required placeholder="Message" name="message" />
            <select name="customer-type">
                <option value="residential">Residential</option>
                <option value="commercial">Commercial & Industrial</option>
                <option value="other">Other</option>
            </select>
            <select name="marketing-source">
                <option value="question">How did you hear about ACDC Solar?</option>
                <option value="google-search">Google Search</option>
                <option value="google-ad">Google Ad</option>
                <option value="facebook">Facebook Ad</option>
                <option value="referral">Referral</option>
                <option value="other">Other</option>
            </select>
            <h3>Installation Address</h3>
            <input name="street" placeholder="Street" type="text" />
            <input name="zip" placeholder="Zip Code" type="text" />
            <input name="state" placeholder="State" type="text" />
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div data-netlify-recaptcha />
            <button className="submit" type="submit"><SubmitBtn /></button>
        </form>
    </Layout>
);

export default Storage;
