import React from 'react';
import PropTypes from 'prop-types';
import Btn from '../Btn';


const storageBtn = {
    background: 'linear-gradient(45deg, rgb(255, 161, 0) 30%, #ffc700 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    fontSize: '1rem',
    height: 48,
    padding: '30px',
    boxShadow: '0 3px 5px 2px rgb(116, 167, 247, .3)',
    minWidth: '200px',
    marginTop: '10px',
};


const StorageBtn = ({ label }) => (
    <Btn
        label={label}
        to="/storage"
        className="nav-links"
        style={storageBtn}
    />

);

StorageBtn.propTypes = {
    label: PropTypes.string,
};

StorageBtn.defaultProps = {
    label: 'Energy Storage',
};

export default StorageBtn;
