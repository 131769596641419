import React from 'react';
import Btn from '../Btn';


const learnBtn = {
    background: 'linear-gradient(45deg, rgb(86, 204, 61) 30%, #36f20e 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    fontSize: '1rem',
    height: 48,

    padding: '30px',
    boxShadow: '0 3px 5px 2px rgb(116, 167, 247, .3)',
    minWidth: '200px',
    marginTop: '10px',
};


const LearnBtn = () => (
    <Btn
        label="Learn Solar"
        to="/learn"
        className="nav-links"
        style={learnBtn}
    />

);

export default LearnBtn;
