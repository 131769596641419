import React from 'react';
import '../../../grid.css';
import Step4img from '../../images/Step4';

const step = {
    fontSize: '1rem',
    padding: '30px',
    minWidth: '100%',
    marginTop: '10px',
    height: 400,
};

const Step4 = () => (
    <div style={step}>
        <h1>Design</h1>
        <div className="row">
            <p className="col span-1-of-2">After you sign up, we schedule an on-site visit to review the structure and electric equipment at your home. We take this information to our engineering team and finalize your system designs and then start permitting and processing the necessary applications for your system. This is when any changes to the design would happen. If there are any changes, we come right back to you with the reason why we think it’s best to change it. We do not both our customers for minor changes that will not affect the install or their return, but some issues like any structural or electrical deficiencies need to be addressed.</p>
            <p className="col span-1-of-2"><Step4img /></p>
        </div>
    </div>
);

export default Step4;
