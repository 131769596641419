import React from 'react';
import '../../../grid.css';
import Step2img from '../../images/Step2';

const step = {
    fontSize: '1rem',
    padding: '30px',
    minWidth: '100%',
    marginTop: '10px',
    height: 500,
};

const Step2 = () => (
    <div style={step}>
        <h1>Learn</h1>
        <div className="row">
            <p className="col span-1-of-2">
            Think about it, saving money every year with solar is like giving yourself a raise... if you spend less of your salary on your home, you have more in the bank, right? ACDC solar is a no-pressure organization, we believe that installing solar is a no-brainer, and we simply teach our customers about solar until they see the &nbsp;
                <em>light</em>
            .  Once we have your system designed, we review the proposal and answer all your questions in person, over the phone, or online. Food for thought, a lot of people take out a 30-year mortgage to buy a home because they believe owning is better than renting, well, solar typically pays for itself in less than 7 years and is engineered to last for 25... Stop RENTING your electricity from your utility, OWN IT!
            </p>
            <p className="col span-1-of-2"><Step2img /></p>
        </div>
    </div>
);

export default Step2;
