
const financialTerms = [
    { term: 'Investment Tax Credit (ITC)', value: 'The ITC is a federal tax credit that is 26% of the installed price on both home solar and business owned arrays. All equipment necessary for the installation of the solar panels is usually eligible. With the exception for roofs and lighting on Carports. Check with your tax advisor for your eligibility. ' },
    { term: 'Depreciation of Solar Energy Property', value: 'With new tax code, businesses are allowed to write off 100% of the depreciation basis in the first year. At a 21% tax rate, the cash value is 18.27% of the installed price. Check with your tax advisor for your eligibility. Depreciation basis = 100% - (ITC percentage / 2)... i.e. 88% = 100% - (26%/2) (for 2020)' },
    { term: 'Transition Renewable Energy Credits (TRECs)', value: 'At their core, TRECs are very similar to SRECs: both incentives are based on the production of your solar panel system, and you receive certificates based on megawatt-hour (MWh) of solar production. However, SRECs and TRECs vary in two important ways: fixed versus variable pricing, and factorization. While Landfill or brownfield installations, as well as, net metered non-residential rooftop or carport have a factor of 1. Net metered Residential: ground mount, rooftop, or carport, and, Net metered non-residential ground mounts have a factor of 0.6.' },
    { term: 'Capital Lease', value: 'For the leasee, a Capital Lease is essentially a laon; the leasee is eligible to receive all the tax and environmantal credits the system is eligible for. A Capital lease is a lease agreement in which the lessor agrees to transfer the ownership rights to the lessee after the completion of the lease period. Capital or finance leases are long term and non cancellable in nature. Available for businesses only.' },
    { term: 'Operating Lease', value: 'And operating lease and a lease-to-own option that ACDC loves. It is not specific to solar but provides huge benefits to for-profit customers without a tax appetite. In this case, the lender would tax the tax incentives and provide a fixed, interest free payment for 7 or 10 years depending on credit and location for the customer/site. Then at the end of the term, there is a buyout. The buyout is about 85% of the installed price. So in lieu of interest, you split the tax benefits with the bank in an off-balance sheet agreement. The monthly payments are 100% tax deductible and the buyout are eligible for the ITC (most likely 10% by that time). Available for businesses with investment grade credit only.  ' },
    { term: 'Power Purchase Agreement (PPA)', value: 'An Power purchase agreement is the solar at its lowest on the totem pole where customers pay per kWh produces from the system… Typically PPAs are 0$ upfront and come with an agreed upon rate for energy. Sometimes these rates can change. PPAs should be left to large organizations that have the resources to vet each proposal. There are many pitfalls that can arise from a seemingly fair PPA. The biggest issue ACDC has with PPAs it the assumed inflation rate for utility rates and the subsequent inflation rate for the PPA payments. We feel strongly that many inflations rates used by third parties are unjustified and provide customers very little to no benefits. ACDC provides PPAs in certain use cases but ALWAYS prefers a customer to choose one of our purchase options.  ' },
    { term: 'Power Purchase Lease (PPL)', value: 'Very similar to a PPA with one main exception. Instead of paying per kWh, customer pay to rent the equipment and keep any energy, and sometimes performance-based incentives produced.' },
    { term: 'Performance-Based Incentives (PBIs) ', value: 'PBI are fixed values based per kWh that are earned by either producing energy by a solar or discharging energy by an ESS. They are another way states are rewarding customers for installing Solar and ESS with ACDC Solar.' },
    { term: 'Massachusett\u0027s SMART Program', value: 'An newly launched incentive program in Massachusetts that is the next step in DER incentives. It provides both solar PV and ESS PBIs that last between 10-20 years depending customer type. SMART is the culmination of the last few decades of renewable energy incentives. ACDC believes it is one of the best programs ever created.' },
    { term: 'Non-bypassable charges. (NBCs)', value: 'Non-bypassable charges are per-kilowatt hour charges that are built into utility electric rates. They add up to approximately 2-3 cents per kWh and go towards funding energy efficiency, low-income customer assistance, and other related programs.' },
    { term: 'Behind the Meter (BTM) ', value: 'Generally speaking, most residential and commercial systems installed are what we call behind the meter, meaning most if not all energy value is applied to the bill of the facility where the energy is generated, and not credits to a third party off-site. This does not mean that if you net-meter energy you are no longer BTM eligible. Net metering is not a factor when determining BTM status. Though the ITC and depreciation are not affected by BTM status, other incentives may be.' },
];

export default financialTerms;
