import { Link } from 'gatsby';
import React from 'react';
import { SocialIcon } from 'react-social-icons';

import './header.css';
import logo from '../images/ACDC_FINAL_WEB_lrg_WHTbckgrnd.jpg';
import MenuBtn from './menu';


const Header = () => (
    <header
        style={{
            marginBottom: '1rem',
        }}
    >
        <div
            style={{
                paddingTop: '1rem',
                display: 'flex',
            }}
        >
            <MenuBtn />
            <div className="socialIcons col ">
                <SocialIcon url="http://instagram.com/acdcsolar" className="socialIcons" target="_blank" rel="noopener" />
                <SocialIcon url="https://www.linkedin.com/company/acdc-solar-llc" className="socialIcons" target="_blank" rel="noopener" />
                <SocialIcon url="http://facebook.com/acdcsolar" className="socialIcons" target="_blank" rel="noopener" />
                <a href="mailto:info@acdcsolar.com" target="_blank" rel="noopener noreferrer"><SocialIcon network="email" className="socialIcons" /></a>
                <p className="socialIcons">Speak direct with a Solar Expert:  (888) 205-2462</p>

            </div>
            <div className="logo col span-1-of-5">
                <Link
                    to="/"
                    style={{
                        color: '#191919',
                        textDecoration: 'none',
                    }}
                >
                    <img className="logo" src={logo} alt="ACDC Solar, LLC Logo" />
                </Link>
            </div>

        </div>
    </header>
);

export default Header;
