import React from 'react';
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import { Link } from 'gatsby';


const Btn = ({
    onClick, label, to, color, className, size, fullWidth, variant, disabled, style,
}) => (to ? (
    <Button
        type="button"
        variant={variant}
        className={className}
        fullWidth={fullWidth}
        onClick={onClick}
        component={Link}
        to={to}
        color={color}
        size={size}
        disabled={disabled}
        style={style}
    >
        {label}
    </Button>
) : (
    <Button
        type="button"
        variant={variant}
        className={className}
        fullWidth={fullWidth}
        onClick={onClick}
        color={color}
        size={size}
        disabled={disabled}
        style={style}
    >
        {label}
    </Button>
));

Btn.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    fullWidth: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.string,
    style: PropTypes.object,
};

Btn.defaultProps = {
    to: null,
    color: null,
    className: 'button',
    size: 'small',
    fullWidth: true,
    onClick: null,
    variant: 'outlined',
    disabled: false,
    style: {},
};

export default Btn;
