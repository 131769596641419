import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
    textTransform: 'capitalize',
  },
});

class ResiliencyPanels extends React.Component {
  state = {
    expanded: null,
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Never go without a light</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
                By design, when your building loses power, a grid-tied solar PV system without storage will shut down for technical and safety reasons. But with your new energy storage system, you can keep your home safe and well lit, and your business <em>in business</em> leaving the competition in the dark.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Protect your inventory</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
             What happens if your power goes out and you have valuable inventory in climate-controlled storage? With our solar PV+ storage system, your cold hard cash will not melt!
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

ResiliencyPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResiliencyPanels);
