import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
    textTransform: 'capitalize',
  },
});

class PeakShavingPanels extends React.Component {
  state = {
    expanded: null,
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Use your solar energy when it&#39;s most valuable</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
                We have already seen radical shift towards TOU rate tariffs for <em>all</em> customers of the three largest utilities in the California. Meaning net metered solar energy is not always the same value as it is when you purchase it back, in many cases solar energy loses significant value. And you can expect more tariff or policy changes over the life of your system. Who knows what the future holds? However, with ACDC Solar, your customizable PV+ storage you can use your solar energy when it is most valuable to you, protecting your investment and putting you back in control!
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

PeakShavingPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PeakShavingPanels);
