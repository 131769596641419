import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

function BusinessPanels(props) {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                        26% Investment Tax Credit
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        System owners are eligible for a 26% ITC on the system installed price purchased in 2019. If there is unused credit, it can be carried forward.
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                        SECTION 179 DEPRECIATION
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                            You can depreciate 100% of the depreciation basis (87% of the installed price) in year 1. At a 21% tax bracket, that is worth about 18% of the system installed price.
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
            Save on Demand & Supply Charges
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
            The most significant parts of commercial bills are typically demand
            (kW) and supply (kWh). Utilities take the highest power draw at any
            point in the billing cycle and consider that your peak demand that
            month, even if it is just for a moment. When we install solar PV+
            storage, we can target those peak demand periods and lower demand
            costs significantly. Depending on analysis of your Green Button Data
            (GBD), we optimize for either demand, supply or in some cases both
            depending on the season.
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}

BusinessPanels.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BusinessPanels);
