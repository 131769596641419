import React from 'react';
import Btn from '../Btn';

const businessBtn = {
    background: 'linear-gradient(45deg, rgb(255, 214, 0)40%, #fcf300 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    fontSize: '1rem',
    height: 48,
    padding: '30px',
    boxShadow: '0 3px 5px 2px rgb(116, 167, 247, .3)',
    minWidth: '200px',
    marginTop: '10px',
};

const BusinessBtn = () => (
    <Btn
        label="Commercial Solar"
        to="/business"
        className="nav-links"
        style={businessBtn}
    />

);

export default BusinessBtn;
