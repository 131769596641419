import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import '../grid.css';
import './review.css';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        marginBottom: '25px',
    },
});


function Review(props) {
    const { classes } = props;

    return (
        <div className="row">
            <div className="col span-1-of-1">
                <Paper className={classes.root} elevation={3}>
                    <div>
                        <blockquote>
                        Just had solar panels installed before the end of the year. Process was easy, sales rep, Bob walked us through the process step-by-step, provided all the data, demonstrated savings. He and owner were on site the day of install to make sure everything went smoothly. It did! Glad we went with ACDC Solar. Ready to start saving!!!
                        </blockquote>
                        <footer>
                            <cite>
                - Cesar J. Summit, NJ
                            </cite>
                        </footer>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

Review.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Review);
